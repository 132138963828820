





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import vClickOutside from "v-click-outside";
import { today } from "@/helpers/date";

@Component({ directives: { clickOutside: vClickOutside.directive } })
export default class WeekDatePicker extends Vue {
  @Prop(Date) readonly value!: Date;
  isVisible = false;
  today = today;

  toggleDatePicker() {
    this.isVisible = !this.isVisible;
  }

  onClickOutside() {
    this.isVisible = false;
  }

  setDate(value: Date) {
    this.$emit("input", value);
  }
}
