



























import Vue from "vue";
import { Component } from "vue-property-decorator";
import CreateUserForm from "@/components/users/CreateUserForm.vue";
import NotificationSettings from "@/components/users/NotificationSettings.vue";
import PasswordResetForm from "@/components/users/PasswordResetForm.vue";
import { UserModule } from "@/store/modules/user";

@Component({ components: { CreateUserForm, NotificationSettings, PasswordResetForm } })
export default class Settings extends Vue {
  created() {
    UserModule.fetchUsers();
  }

  get users() {
    return UserModule.users;
  }
}
