















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IRecipientSession } from "@/types";
import HoldsList from "@/components/recipient/HoldsList.vue";
import AllocationList from "@/components/recipient/AllocationList.vue";
import HoldStatusTag from "@/components/ui/HoldStatusTag.vue";
import RecipientSessions from "@/store/modules/recipient-sessions";
import SessionModal from "@/components/recipient/SessionModal.vue";
import toast from "@/helpers/toast";
import { formatTime } from "@/helpers/date";
import { capitalize } from "lodash";

@Component({
  components: { HoldsList, AllocationList, HoldStatusTag, SessionModal },
  filters: { capitalize, formatTime }
})
export default class SessionCard extends Vue {
  @Prop({ required: true }) readonly recipientSession!: IRecipientSession;
  @Prop({ required: true }) readonly recipientSessions!: IRecipientSession[];
  @Prop() readonly baseId!: string;
  @Prop() readonly recipientId!: string;
  isOpen = false;

  toggleIsOpen() {
    this.isOpen = !this.isOpen;
  }

  confirmSessionDeletion() {
    this.$buefy.dialog.confirm({
      message: "Are you sure you wish to remove this session?",
      type: "is-danger",
      onConfirm: async () => {
        if (this.recipientSession.id) {
          await RecipientSessions.deleteSession({ recipientId: this.recipientId, sessionId: this.recipientSession.id });
          toast.success("Deleted session.");
        }
      }
    });
  }
}
