










import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({ components: { ValidationProvider } })
export default class ValidatedSelect extends Vue {
  @Prop() readonly name!: string;
  @Prop() readonly rules!: Record<string, any>;
  @Prop() readonly value!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: "" }) readonly placeholder!: string;
  @Prop({ default: "" }) readonly help!: string;

  @Emit()
  input(event: Event) {
    return event;
  }
}
