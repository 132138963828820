






import { Component, Prop } from "vue-property-decorator";
import Checkbox from "@/components/ui/Checkbox.vue";
import Vue from "vue";
import ActiveRecipient from "@/store/modules/active-recipient";

@Component({ components: { Checkbox } })
export default class OnboardingCheckbox extends Vue {
  @Prop() readonly id!: string;
  @Prop() readonly name!: string;
  @Prop() readonly value!: boolean;
  @Prop({ default: "" }) readonly label!: string;

  async toggleValue() {
    await ActiveRecipient.updateRecipient({
      id: this.id,
      [this.name]: !this.value
    });
  }
}
