




























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Router from "@/router";
import { formatDate, subWeeks, addWeeks } from "@/helpers/date";
import WeekDatePicker from "@/components/ui/WeekDatePicker.vue";

@Component({ components: { WeekDatePicker } })
export default class WeekDateControls extends Vue {
  @Prop(Date) readonly date!: Date;

  goToDate(datetime: Date) {
    const date = formatDate(datetime, "yyyy-MM-dd");
    Router.push({ path: "/sessions/week", query: { date } });
  }

  get previousWeek() {
    return subWeeks(this.date, 1);
  }

  get nextWeek() {
    return addWeeks(this.date, 1);
  }
}
