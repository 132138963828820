
































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IAllocation, IAllocationCategory } from "@/types";
import App from "@/store/modules/app";
import { sortBy } from "lodash";
import AllocationQuantitesSelect from "@/components/ui/AllocationQuantitesSelect.vue";

@Component({ components: { AllocationQuantitesSelect } })
export default class AllocationQuantitiesInput extends Vue {
  @Prop() readonly value!: IAllocation[];
  allocationCategories: any = [];

  created() {
    const allocations = this.value;

    const allocationCategories = App.categories.map(fc => {
      const allocation = allocations.find(a => a.categoryId === fc.id);

      if (allocation) {
        return {
          id: allocation.id,
          enabled: true,
          quantity: allocation.quantity,
          categoryId: fc.id,
          name: fc.name
        };
      } else {
        return {
          id: null,
          enabled: false,
          quantity: "0.0",
          quantityLabel: "",
          categoryId: fc.id,
          name: fc.name
        };
      }
    });

    this.allocationCategories = sortBy(allocationCategories, ["name"]);
  }

  @Watch("allocationCategories", { immediate: true, deep: true })
  onAllocationChange() {
    const allocations = this.allocationCategories
      .filter((allocation: IAllocationCategory) => allocation.enabled)
      .map((allocation: IAllocationCategory) => {
        return {
          categoryId: allocation.categoryId,
          quantity: allocation.quantity,
          id: allocation.id
        };
      });
    this.$emit("input", allocations);
  }

  setCategoryQuantity(category: IAllocationCategory, quantity: string) {
    category.quantity = quantity;
  }

  getCategoryQuantity(category: IAllocationCategory): string {
    if (category.quantity === "0.0") {
      return "";
    } else {
      return category.quantity;
    }
  }
}
