








import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";

@Component({ components: { ValidationObserver } })
export default class ValidatedForm extends Vue {
  @Emit()
  submit(event: Event) {
    return event;
  }
}
