













































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IRecipientSession } from "@/types";
import RecipientSessions from "@/store/modules/recipient-sessions";
import ModalForm from "@/components/ui/ModalForm.vue";
import SessionLabel from "@/components/ui/SessionLabel.vue";
import ValidatedDate from "@/components/ui/ValidatedDate.vue";
import toast from "@/helpers/toast";
import { startOfDayString, endOfDayString } from "@/helpers/date";

@Component({ components: { SessionLabel, ValidatedDate, ModalForm } })
export default class HoldModal extends Vue {
  @Prop() recipientSessions!: IRecipientSession[];
  @Prop() recipientId!: string;
  startDate: Date = new Date();
  endDate: Date | null = null;
  disableEndDate = false;
  allSessions = false;
  sessions: IRecipientSession[] = [];
  isOpen = false;

  openModal() {
    this.sessions = this.recipientSessions.map(s => {
      return { enabled: false, ...s };
    });
    this.isOpen = true;
  }

  async saveHold() {
    if (this.sessionHolds.length > 0) {
      await RecipientSessions.createHolds({ recipientId: this.recipientId, holds: this.sessionHolds });
      this.$emit("close");
      toast.success("Session hold created.");
      this.startDate = new Date();
      this.endDate = null;
      this.disableEndDate = false;
      this.allSessions = false;
      this.sessions = [];
    }
  }

  get sessionHolds() {
    const endDate = this.disableEndDate ? null : this.endDate;

    return this.sessions
      .filter(s => s.enabled)
      .map(s => {
        return {
          recipientSessionId: s.id,
          startsAt: startOfDayString(this.startDate),
          endsAt: endDate ? endOfDayString(endDate) : ""
        };
      })
      .filter(h => h !== null);
  }

  toggleEndDate(isDisabled: boolean) {
    this.disableEndDate = isDisabled;
  }

  toggleAllSessions() {
    this.allSessions = !this.allSessions;
    this.sessions.forEach(s => (s.enabled = this.allSessions));
  }
}
