











































import Vue from "vue";
import { Component } from "vue-property-decorator";
import InfoField from "@/components/ui/InfoField.vue";
import RecipientStatusTag from "@/components/recipient/RecipientStatusTag.vue";
import HoldModal from "@/components/recipient/HoldModal.vue";
import SessionCard from "@/components/recipient/SessionCard.vue";
import SessionModal from "@/components/recipient/SessionModal.vue";
import RecipientSessions from "@/store/modules/recipient-sessions";
import { IRecipientSession } from "@/types";
import { sortBy } from "lodash";
import ActiveRecipient from "@/store/modules/active-recipient";
import { dayIndexFromString } from "@/helpers/date";

@Component({ components: { InfoField, HoldModal, RecipientStatusTag, SessionCard, SessionModal } })
export default class RecipientSortingSessions extends Vue {
  selectedSession: IRecipientSession | null = null;
  id = ActiveRecipient.details?.id ?? null;
  baseId = ActiveRecipient.details?.baseId;
  status = ActiveRecipient.status;
  loading = true;

  async created() {
    if (this.id && this.baseId) {
      await RecipientSessions.fetchSessions(this.id);
    }
    this.loading = false;
  }

  get recipientSessions() {
    return sortBy(RecipientSessions.sessions, (s: any) => `${dayIndexFromString(s.day)}-${s.time}`);
  }
}
