import { render, staticRenderFns } from "./RecipientsBaseFilter.vue?vue&type=template&id=707c66ae&"
import script from "./RecipientsBaseFilter.vue?vue&type=script&lang=ts&"
export * from "./RecipientsBaseFilter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports