



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SubmitButton from "@/components/ui/SubmitButton.vue";
import ValidatedForm from "@/components/ui/ValidatedForm.vue";

@Component({ components: { SubmitButton, ValidatedForm } })
export default class ModalForm extends Vue {
  @Prop() readonly loading!: string;
  @Prop({ default: false }) readonly value!: boolean;

  get isOpen() {
    return this.value;
  }

  close() {
    this.$emit("input", false);
  }

  open() {
    this.$emit("input", true);
  }

  handleSubmit() {
    this.$emit("submit");
    this.close();
  }
}
