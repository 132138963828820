















import { Component, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import Vue from "vue";

@Component({ components: { ValidationProvider } })
export default class ValidatedDate extends Vue {
  @Prop({ default: "" }) readonly value!: Date | string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop(Object) readonly rules!: any;

  get dateValue() {
    if (!this.value || this.value === "") {
      return null;
    } else {
      return new Date(this.value);
    }
  }

  get label() {
    return this.name.replace(/-/g, " ");
  }

  get name() {
    return this.$attrs.name || this.$attrs.label;
  }
}
