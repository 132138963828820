






import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class Checkbox extends Vue {
  @Prop() readonly value!: boolean;
  @Prop({ default: "" }) readonly label!: string;
}
