



























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SessionPlans from "@/store/modules/session-plans";
import PrintButton from "@/components/ui/PrintButton.vue";
import { formatDate } from "@/helpers/date";
import Router from "@/router";

@Component({ components: { PrintButton }, filters: { formatDate } })
export default class GenerateDescriptionsButton extends Vue {
  @Prop(String) readonly id!: string;
  includeOnHold = false;

  get recipients() {
    if (this.includeOnHold) {
      return SessionPlans.orderedRecipients;
    } else {
      return SessionPlans.orderedRecipients.filter(r => r.status !== "on_hold");
    }
  }

  get sessionDate() {
    return SessionPlans.planDetails?.session.date;
  }

  goBack() {
    Router.go(-1);
  }

  print() {
    window.print();
  }
}
