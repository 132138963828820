










import Vue from "vue";
import { Component } from "vue-property-decorator";
import AllRecipients from "@/store/modules/all-recipients";
import { IStatus } from "@/types";

@Component
export default class RecipientsBaseFilter extends Vue {
  get filteredStatus(): IStatus[] {
    return AllRecipients.filteredStatus;
  }
  toggleStatusFilter(name: string) {
    AllRecipients.toggleStatusFilter(name);
  }
}
