














import Vue from "vue";
import SessionPlans from "@/store/modules/session-plans";
import { Component, Prop } from "vue-property-decorator";
import { dateOnDayOfWeek, formatDate } from "@/helpers/date";
import SessionRecipientList from "@/components/sessions/SessionRecipientList.vue";

@Component({ components: { SessionRecipientList }, filters: { formatDate } })
export default class ListSessions extends Vue {
  @Prop({ required: true }) readonly weekOfDate!: Date;
  @Prop({ required: true }) readonly day!: string;

  get sessionDate() {
    return dateOnDayOfWeek(this.weekOfDate, this.day);
  }

  get plans() {
    return SessionPlans.plansForDay(this.day);
  }
}
