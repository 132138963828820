


















import Vue from "vue";
import { Component } from "vue-property-decorator";
import OnboardingCheckbox from "@/components/ui/OnboardingCheckbox.vue";
import ActiveRecipient from "@/store/modules/active-recipient";

@Component({ components: { OnboardingCheckbox } })
export default class RecipientOnboardingChecks extends Vue {
  get recipient() {
    return ActiveRecipient.details;
  }
}
