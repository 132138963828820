
























import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import AddressField from "@/components/ui/AddressField.vue";
import BaseSelect from "@/components/ui/BaseSelect.vue";
import ValidatedDate from "@/components/ui/ValidatedDate.vue";
import ValidatedInput from "@/components/ui/ValidatedInput.vue";
import ValidatedForm from "@/components/ui/ValidatedForm.vue";
import { IRecipient } from "@/types";
import { defaultRecipientDetails } from "@/store/modules/active-recipient";

@Component({ components: { AddressField, BaseSelect, ValidatedDate, ValidatedForm, ValidatedInput } })
export default class RecipientForm extends Vue {
  @Prop(Object) readonly recipient!: IRecipient;
  recipientDetails: IRecipient = { ...defaultRecipientDetails, contact: { ...defaultRecipientDetails.contact } };

  @Emit()
  submit(): IRecipient {
    return this.recipientDetails;
  }

  async created() {
    if (this.recipient) {
      this.recipientDetails = { ...this.recipient, contact: { ...this.recipient.contact } };
    }
  }
}
