















import { Component, Emit, Prop } from "vue-property-decorator";
import Vue from "vue";
import App from "@/store/modules/app";
import ValidatedSelect from "@/components/ui/ValidatedSelect.vue";

@Component({ components: { ValidatedSelect } })
export default class BaseSelect extends Vue {
  @Prop({ default: false }) readonly all!: boolean;
  @Prop({ default: false }) readonly required!: boolean;
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: "" }) readonly help!: string;
  @Prop() readonly value!: string;

  allValue = 0;

  async created() {
    await App.fetchBases();
  }

  get list() {
    return App.bases;
  }

  @Emit()
  input(event: Event) {
    return event;
  }
}
