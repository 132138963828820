





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AllocationHelper from "@/helpers/allocations";

@Component
export default class SessionRecipient extends Vue {
  @Prop(String) readonly quantity!: string;
  @Prop(Object) readonly recipient!: any;

  recipientOnHold(recipient: any) {
    return recipient.status === "on_hold";
  }

  viewRecipient() {
    this.$router.push(`/recipients/${this.recipient.id}`);
  }

  get quantityLabel() {
    return AllocationHelper.quantityLabel(this.quantity);
  }
}
