








import { Component, Emit, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import Vue from "vue";

@Component({ components: { ValidationProvider } })
export default class ValidatedInput extends Vue {
  @Prop() readonly value!: string;
  @Prop({ default: "text" }) readonly type!: string;
  @Prop({ default: "" }) readonly help!: string;
  @Prop(Object) readonly rules!: any;

  get label() {
    return this.name.replace(/-/g, " ");
  }

  get name() {
    return this.$attrs.name || this.$attrs.label;
  }

  @Emit()
  input(event: Event) {
    return event;
  }
}
