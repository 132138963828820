















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import RecipientSessions from "@/store/modules/recipient-sessions";
import { formatDate } from "@/helpers/date";
import { sortBy } from "lodash";

@Component({ filters: { formatDate } })
export default class HoldTr extends Vue {
  @Prop() readonly holds!: any[];
  @Prop() readonly recipientId!: string;

  get sortedHolds() {
    return sortBy(this.holds, ["startsAt"]);
  }

  removeHold(holdId: string) {
    RecipientSessions.deleteHold({ recipientId: this.recipientId, holdId });
  }
}
