

























import Vue from "vue";
import { Component } from "vue-property-decorator";
import RecipientsList from "@/components/recipients/RecipientsList.vue";
import RecipientsFilterPanel from "@/components/recipients/RecipientsFilterPanel.vue";
import AllRecipients from "@/store/modules/all-recipients";

@Component({ components: { RecipientsList, RecipientsFilterPanel } })
export default class ListRecipients extends Vue {
  loading = AllRecipients.filteredList.length === 0;

  async created() {
    await AllRecipients.fetchRecipients();
    this.loading = false;
  }

  get recipientsList() {
    return AllRecipients.filteredList;
  }
}
