






















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import SessionRecipient from "@/components/sessions/SessionRecipient.vue";
import { sortBy } from "lodash";
import { formatDate, formatTime } from "@/helpers/date";
import { ISessionPlan } from "@/types";

@Component({ components: { SessionRecipient }, filters: { formatTime } })
export default class SessionRecipientList extends Vue {
  @Prop() readonly plan!: ISessionPlan;

  sortRecipients(list: any) {
    return sortBy(list, ["name"]);
  }

  get date() {
    const sessionDate = new Date(this.plan.session.date);
    return formatDate(sessionDate, "yyyy-MM-dd");
  }
}
