













import Vue from "vue";
import { Component } from "vue-property-decorator";
import PasswordResetForm from "@/components/users/PasswordResetForm.vue";

@Component({ components: { PasswordResetForm } })
export default class ResetPassword extends Vue {}
