


















import Vue from "vue";
import { Component } from "vue-property-decorator";
import GlobalLoader from "@/components/ui/GlobalLoader.vue";
import NavBar from "@/components/ui/NavBar.vue";
import { UserModule } from "@/store/modules/user";

@Component({ components: { GlobalLoader, NavBar } })
export default class App extends Vue {
  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get showNav() {
    const isPublicRoute = this.$route.matched.every(r => r.meta.public);
    return !isPublicRoute && this.isAuthenticated;
  }
}
