




















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import RecipientStatusTag from "@/components/recipient/RecipientStatusTag.vue";
import { IRecipientListItem } from "@/types";

@Component({ components: { RecipientStatusTag } })
export default class RecipientsList extends Vue {
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: [] }) readonly recipients!: IRecipientListItem[];

  viewRecipient(recipient: IRecipientListItem) {
    this.$router.push(`/recipients/${recipient.id}`);
  }
}
