




















































import { capitalize, sortBy } from "lodash";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import App from "@/store/modules/app";
import SessionPlans from "@/store/modules/session-plans";
import SessionRecipient from "@/components/sessions/SessionRecipient.vue";
import PrintButton from "@/components/ui/PrintButton.vue";
import { formatDate } from "@/helpers/date";

@Component({ components: { SessionRecipient, PrintButton }, filters: { formatDate } })
export default class ShowSession extends Vue {
  @Prop(String) readonly id!: string;

  allocationsForCategory(categoryId: string) {
    const allocations = SessionPlans.allocationsForCategory(categoryId).map((a: any) => {
      return { ...a, recipient: SessionPlans.recipientById(a.recipientId) };
    });

    return sortBy(allocations, a => [a.recipient.status, a.recipient.name]);
  }

  recipientById(recipientId: string) {
    return SessionPlans.recipientById(recipientId);
  }

  async created() {
    App.enableLoading();
    const baseId = this.$route.query.baseId?.toString();
    const sessionId = this.$route.params.id?.toString();
    const date = this.$route.query.date?.toString();

    await App.fetchCategories();
    await SessionPlans.fetchPlanDetails({ baseId, sessionId, date });

    App.disableLoading();
  }

  capitalize(str: string) {
    return capitalize(str);
  }

  imagePath(imageName: string) {
    const images = require.context("@/assets/images/foods", false, /\.png$/);
    return images("./" + imageName + "-min.png");
  }

  get categories() {
    return sortBy(App.categories, "name");
  }

  get sessionDate() {
    if (SessionPlans.planDetails?.session.date) {
      return new Date(`${SessionPlans.planDetails.session.date}T${SessionPlans.planDetails.session.time}`);
    } else {
      return null;
    }
  }
}
