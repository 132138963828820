



















import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import ValidatedInput from "@/components/ui/ValidatedInput.vue";

@Component({ components: { ValidatedInput } })
export default class PasswordField extends Vue {
  @Prop({ default: false }) readonly withConfirmation!: boolean;
  @Prop() readonly value!: string;
  confirmationValue = "";

  @Emit()
  input(event: Event) {
    return event;
  }
}
