












































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { IRecipient } from "@/types";
import AddressField from "@/components/ui/AddressField.vue";
import BaseSelect from "@/components/ui/BaseSelect.vue";
import ValidatedDate from "@/components/ui/ValidatedDate.vue";
import ValidatedInput from "@/components/ui/ValidatedInput.vue";
import ValidatedForm from "@/components/ui/ValidatedForm.vue";
import { defaultRecipientDetails } from "@/store/modules/active-recipient";
import SignupService from "@/services/signup-service";

@Component({ components: { AddressField, BaseSelect, ValidatedDate, ValidatedForm, ValidatedInput } })
export default class CreateRecipient extends Vue {
  success = false;
  recipientDetails: IRecipient = {
    ...defaultRecipientDetails,
    contact: { ...defaultRecipientDetails.contact }
  };

  async createRecipient() {
    try {
      await SignupService.create(this.recipientDetails);
      this.success = true;
    } catch (e) {
      this.success = false;
    }
  }
}
