





































import Vue from "vue";
import { Component } from "vue-property-decorator";
import AllRecipients from "@/store/modules/all-recipients";
import { UserModule } from "@/store/modules/user";

@Component
export default class NavBar extends Vue {
  resetRecipientNameFilter() {
    AllRecipients.updateNameFilter("");
  }

  async logout() {
    await UserModule.logout();
  }
}
